<template>
    <div class="page-wrapper pending-items-page-wrap">
        <div class="container-fluid">
            <breadcrumb :items="breadcrumbItems" />
            <div class="page-title">
                <h1>Pending Items</h1>
            </div>
            
            <div>
                <PendingItems />
            </div>
        </div>
    </div>
</template>
<script>
import PendingItems from '../views/Dashboard/partials/PendingItemsInternal.vue';
export default {
    name: 'PendingItemsPage',
    components: {
        PendingItems,
    },
    data: () => ({
        breadcrumbItems: [{ title: "Pending Items", active: true }],
    })
}
</script>
<style src="../assets/css/repdashboard.css"></style>
<style>
.pending-items-page-wrap h2 {
    display: none;
}
</style>